<template>
  <div class="min-vh-100 mt-5 d-flex flex-row align-items-center impressum">
    <CContainer>
    <CRow class="m-auto">
      <CCol :sm="12" class="temp-form">
        <CCard>
          <CCardHeader>
            <h1>
              Impressum
            </h1>
          </CCardHeader>

          <CCardBody>
              <div class="content row ">
                <div class="small-12 columns m-auto">
                  <p>
                    Fidia Pharma GmbH<br>
                    Opladener Straße 149<br>
                    40789 Monheim
                  </p>

                  <p>
                    Tel: 02173 - 8954 - 0<br>
                    Fax: 02173 - 8954 - 100<br>
                    E-Mail: <a href="mailto:service@fidiapharma.de">service@fidiapharma.de</a>
                  </p>

                  <p>
                    Unser Kundenservice-Center ist telefonisch montags bis donnerstags von 09.00h bis 17.00h und freitags 09.00h bis 14.00h erreichbar. Außerhalb der Geschäftszeiten können Sie uns eine Nachricht auf unserem Anrufbeantworter hinterlassen.<br>
                  </p>

                  <p>
                    Geschäftsführer: Dr. Carlo Pizzocaro, Dr. Ranieri Venerosi Pesciolini, Dr. Tito Bracco<br>
                    Inhaltlich Verantwortlicher: Mark Geißler
                  </p>

                  <p>
                    Zuständige Aufsichtsbehörde: Bezirksregierung Düsseldorf<br>
                    Handelsregister Düsseldorf HRB 70948
                  </p>

                  <p>
                    Umsatzsteuer Identifikationsnummer: DE 291 199 590
                  </p>

                  <p>
                    Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, dieses Informationsangebot stetig zu erweitern und zu aktualisieren. Eine Garantie für die Vollständigkeit, Richtigkeit und Aktualität besteht jedoch nicht.
                  </p>

                  <p>
                    <strong>Haftungshinweis/Disclaimer:</strong><br>
                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                  </p>

                  <br>
                  <br>
                </div>
              </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      <CCol class="footerLogin m-auto mt-5  text-center" :md="6">
        2023 © fidia Pharma GmbH | <a href="/">Startseite</a> | <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a>
      </CCol>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'app-impressum',
  components: {
  }
  // setup() {},
}
</script>
